<template>
  <div id="terminal" :class="{ 'night-mode': isNightMode }">
    <div id="mode-switch" @click="toggleMode">{{ isNightMode ? '☀️' : '🌙' }}</div>
    <div id="moonlight" v-if="isNightMode"></div>
    <span class="site-name" v-html="`/${typedText}`"></span>

    <div id="terminal-output" v-html="outputHtml"></div>
    <div id="input-line">
      <span id="prompt">{{ prompt }}</span>
      <input type="text" v-model="userInput" @keyup.enter="processCommand" autofocus>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      prompt: 'explorer@kprsnt.in:~%',
      userInput: '',
      outputLines: [],
      isNightMode: true,
      welcomeMessage: '',
      typedText: '',
      isTypingComplete: false,
      errorMessages: [
        "Oops! '${command}' isn't recognized. Type 'help' to see available commands.",
        "The command '${command}' was not found. For data-related commands, try 'help'.",
        "Command not found: ${command}. Type 'help' for a list of commands.",
        "Hmm, I don't know the command '${command}'. Try 'help' for assistance.",
        "Sorry, the command '${command}' isn't recognized. Maybe try 'help' for a list of data commands?",
        "Unrecognized command: '${command}'. Use 'help' to see what you can do."
      ],
      commands: {
        help: this.showHelp, ls: this.listFiles, projects: this.showFunProjects, blog: this.showBlog, social: this.showSocialMedia, connect: this.showConnect, resume: this.showResume, about: this.showAbout, hello: this.showAbout, // Alias for about
        clear: this.clearScreen, mode: this.toggleMode,
      }
    }
  },
  computed: {
    outputHtml() {
      return this.outputLines.join('<br>');
    }
  },
  methods: {
    processCommand() {
      if (this.outputLines.length === 1 && this.outputLines[0] === this.welcomeMessage) {
        this.outputLines.push(''); // Add a newline after the welcome message
      }
      this.outputLines.push(`<span class="command-input">${this.prompt} ${this.userInput}</span>`);
      const [command, ...args] = this.userInput.trim().toLowerCase().split(' ');
      if (this.commands[command]) {
        this.commands[command](args);
      } else {
        this.pushErrorMessage(command);
      }
      this.userInput = '';
    },
    pushErrorMessage(command) {
      const randomIndex = Math.floor(Math.random() * this.errorMessages.length);
      const selectedMessage = this.errorMessages[randomIndex].replace('${command}', command);
      this.outputLines.push(`<span class="command-response">${selectedMessage}</span>`);
    },
    showHelp() {
      this.outputLines.push(`
<span class="command-response">Available commands:
  help     ls     about     blog      projects      social      connect     resume      clear     mode</span>
      `);
    },
    listFiles() {
      this.outputLines.push('<span class="command-response">about  social  connect  resume  projects  blog</span>');
    },
    showSocialMedia() {
      this.outputLines.push(`
<span class="command-response">Social Media Links:
Twitter/X: <a href="https://x.com/prashanth_29" target="_blank">X Link</a>
LinkedIn: <a href="https://www.linkedin.com/in/prashanth-kumar-kadasi-b5281765/" target="_blank">LinkedIn Profile</a>
GitHub: <a href="https://github.com/kprsnt2" target="_blank">Github Link</a>
Tableau Public: <a href="https://public.tableau.com/app/profile/prashanth.kumar2458/vizzes" target="_blank">Tableau Profile</a>
Instagram: <a href="https://www.instagram.com/kprsnt/" target="_blank">Instagram Profile</a>
YouTube: <a href="https://www.youtube.com/@kprsnt" target="_blank">YouTube Link</a></span>
      `);
    },
    showFunProjects() {
  this.outputLines.push(`
<span class="command-response">Check out my fun projects: <a href="https://storygemini.streamlit.app/" target="_blank">AI Story Teller</a>  <a href="https://plotcharts.streamlit.app/" target="_blank">Plot Charts</a>  <a href="https://github.com/kprsnt2" target="_blank">GitHub</a></span>
  `);
},
showBlog() {
  this.outputLines.push(`
<span class="command-response">Read my latest blog posts: <a href="https://kprsnt.in/blog" target="_blank">Blog</a></span>
  `);
},
    showConnect() {
      this.outputLines.push(`
<span class="command-response">To connect with me for a 1:1 session:
Please use this link to schedule a meeting: <a href="https://cal.com/kprsnt" target="_blank">Meeting Link</a></span>
      `);
    },
    showResume() {
      this.outputLines.push(`
<span class="command-response">View my resume: <a href="https://drive.google.com/file/d/1D6IJ6UMDkc715H_GLPbWLxemahpLh18G/view" target="_blank">Resume Link</a></span>
      `);
    },
    clearScreen() {
      this.outputLines = [this.welcomeMessage];
    },
    toggleMode() {
      this.isNightMode = !this.isNightMode;
    },
    typeText() {
      const text = 'kprsnt.in';
      let i = 0;
      const typing = setInterval(() => {
        if (i < text.length) {
          this.typedText += text.charAt(i);
          i++;
        } else {
          clearInterval(typing);
          this.isTypingComplete = true;
        }
      }, 100);
    },
    showAbout() {
      this.outputLines.push(`
<span class="command-response">
Welcome to my site, kprsnt.in

I'm Prashanth Kumar, a Data Analyst with expertise in SQL, BigQuery, Python, Tableau, Looker Studio, and Alteryx (core certified). 

I'm passionate about transforming raw data into meaningful insights that drive business decisions. With a strong foundation in data analysis tools and techniques, I strive to uncover patterns and trends that can lead to improved efficiency and strategic growth.

My experience spans across various aspects of data analysis, from data cleaning and preprocessing to creating insightful visualizations and reports. I'm always eager to learn new technologies and methodologies to enhance my skills and deliver more value in my work.

If you're interested in data analysis, business intelligence, or just want to chat about the latest trends in the tech world, feel free to reach out! I'm always open to connecting with fellow professionals and enthusiasts.
</span>
      `);
    },
  },
  mounted() {
    this.typeText();
    this.welcomeMessage = `
<span class="site-name">${this.typedText}</span>📊📈📉💹📊📉📈🔍💻📈💼🗂️📋📅
<span class="last-login">Last login: ${new Date().toLocaleString()} on ttys009</span>

Welcome to my interactive terminal portfolio!

I'm a Data Analyst passionate about turning data into insights. Explore my skills, projects, and connect with me through various commands.

Personal Projects: <a href="https://storygemini.streamlit.app/" target="_blank">AI Story Teller</a>  <a href="https://plotcharts.streamlit.app/" target="_blank">Plot Charts</a>  <a href="https://github.com/kprsnt2" target="_blank">GitHub</a>

<span class="help-instruction">Type 'help' or 'ls' to see available commands.</span>
    `;
    this.outputLines = [this.welcomeMessage];
  },
} 
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Courier Prime', 'Courier New', monospace;
}

#terminal {
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  transition: background-color 0.5s ease, color 0.5s ease;
  font-size: 16px;
  line-height: 1.4;
  position: relative;
}

#terminal.night-mode {
  background: linear-gradient(to bottom right, #0d1117, #1f2937);
  color: #a8b5d1;
}

#terminal:not(.night-mode) {
  background: linear-gradient(to bottom right, #e3e3e3, #e0e0e0);
  color: #333;
}

#moonlight {
  position: fixed;
  top: -150px;
  right: -150px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
  pointer-events: none;
  z-index: 1;
}

#terminal-output {
  white-space: pre-wrap;
  word-wrap: break-word;
  position: relative;
  z-index: 2;
}

#input-line {
  display: flex;
  margin-top: 10px;
  position: relative;
  z-index: 2;
}

#prompt {
  color: #ff0000 !important;
}

input {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  color: inherit;
}

#terminal.night-mode a {
  color: #ce9178;
}

#terminal:not(.night-mode) a {
  color: #af5b29;
}

a:hover {
  text-decoration: underline;
}

#mode-switch {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  z-index: 3;
}

.site-name {
  color: #8f14f4;
  font-weight: bold;
}

.last-login {
  color: #d8e0e8;
}

.help-instruction {
  color: #12bcec;
}

.command-input {
  color: inherit;
}

.command-response {
  color: #fdbb40;
}

#terminal:not(.night-mode) .command-response {
  color: #398adc;
}
</style>
